@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

.radio {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 43px;
  width: 130px;
  min-width: 130px;
  background: rgba(0, 0, 0, 0.6);
  border-bottom-right-radius: 17px;
  color: white;
  transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
  overflow: hidden;
  user-select: none;
  pointer-events: auto; // КЛІКИ ДОЗВОЛЕНО

  // Відміняє синю підсвітку на iOS та Android
  // -webkit-tap-highlight-color: transparent;

  .hidden {
    display: none;
  }

  &--expanded {
    padding: 8px 10px;
    height: 530px;
    width: 360px;
    backdrop-filter: blur(5px);

    @media (max-width: 500px) {
      position: absolute;
      top: 0;
      justify-content: center;
      padding-top: 50px;
      width: 100%;
      height: 100vh;
      border-bottom-right-radius: unset;
      background: url("../../assets/bg/scratch-black2.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      z-index: 2;
    }
  }

  &__expanded {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
    @media (max-width: 500px) {
      position: absolute;
      top: 0;
      padding: 15px;
    }
  }

  &__compact {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    height: 100%;
    transition: height 0.3s ease-in-out, width 0.3s ease-in-out;
    backdrop-filter: blur(5px);
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 50px;
    gap: 10px;
  }

  &__title-img {
    width: 35px;
  }

  &__cross {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    width: 25px;
    height: auto;
    transition: transform 0.1s ease-in-out;
    &:hover {
      transform: scale(1.1);
    }
  }

  &__title-string {
    font-size: 1.3rem;
    margin-right: 15px;
    color: white;
  }
}

.player {
  display: flex;
  flex-direction: column;
  margin: 5px 0 0 0;
  &__navAudio {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 3px;
    flex-shrink: 0; // Забороняємо елементу зникати при браку простору
    background: linear-gradient(
      to left,
      transparent,
      rgba(black, 0.7),
      transparent
    );
    @media (max-width: 500px) {
      margin: 15px 0 50px 0;
    }
  }

  &__img {
    width: 35px;
    height: auto;
    margin: 0 3px;
    cursor: pointer;
    border-radius: 50%;
    transition: transform 0.3s ease, filter 0.3s ease, box-shadow 0.3s ease;
    // box-shadow: inset 0 0 7px white;
    &:hover {
      transform: scale(1.1);
      box-shadow: 0 0 7px rgba(white, 0.7);
      filter: brightness(1.2) saturate(1.5);
    }
    &:active {
      transform: scale(1);
    }

    &--large {
      width: 45px;
      height: 45px;
      &:nth-of-type(1) {
        width: 40px;
        height: 40px;
      }
      &:nth-of-type(3) {
        width: 50px;
        height: 50px;
      }
      &:nth-of-type(5) {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__trackBar {
    width: 100%;
    // margin-bottom: 10px;
    flex-shrink: 0; // Забороняємо елементу зникати при браку простору
  }

  &__input {
    position: relative;
    -webkit-appearance: none;
    width: 100%;
    height: 12px;
    background: transparent; // Прозорий фон по замовчуванню
    border-radius: 5px;
    outline: none;
    cursor: pointer;

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 12px;
      background: rgba(0, 0, 0, 0.1); // Легкий прозорий фон для всієї смуги
      border-radius: 15px;
      border: 1px solid rgba($theme-color, 0.5);
      box-shadow: 0 0 8px $theme-color;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 0; // Приховуємо повзунок
      height: 0; // Приховуємо повзунок
    }

    &::-moz-range-thumb {
      width: 0; // Приховуємо повзунок
      height: 0; // Приховуємо повзунок
    }

    &::-ms-thumb {
      width: 0; // Приховуємо повзунок
      height: 0; // Приховуємо повзунок
    }

    // Додаємо стилі для видимого повзунка
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 20px; // Розмір повзунка
      height: 20px; // Розмір повзунка
      background-color: rgba(255, 0, 51, 0.001); // Прозорий червоний
      border-radius: 50%;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      width: 20px; // Розмір повзунка
      height: 20px; // Розмір повзунка
      background-color: rgba($theme-color, 0.5); // Прозорий червоний
      border-radius: 50%;
      border: 2px solid rgba(0, 0, 0, 0.5); // Прозорий чорний
      cursor: pointer;
    }

    &::-ms-thumb {
      width: 20px; // Розмір повзунка
      height: 20px; // Розмір повзунка
      background-color: rgba($theme-color, 0.5); // Прозорий червоний
      border-radius: 50%;
      border: 2px solid rgba(0, 0, 0, 0.5); // Прозорий чорний
      cursor: pointer;
    }
  }

  &__time {
    font-size: 14px;
    font-weight: bold;
    text-align: right;
    margin-right: 10px;
  }
}

.playlist {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 380px;
  width: 100%;
  border-radius: 7px;
  padding: 0 10px 10px 10px;
  overflow-y: auto;
  @media (max-width: 500px) {
    max-width: unset;
    height: 100%;
  }

  &__title {
    // font-size: 10px;
    color: $theme-color;

    &:after {
      content: "";
      display: block;
      width: 100%;
      margin: 0 auto;
      border-bottom: 3px solid $theme-color;
    }
  }

  &__ul {
    height: 300px;
    padding-right: 5px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
      background-color: rgba(black, 0.7);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $theme-color;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: darken($theme-color, 40%);
    }
    @media (max-width: 500px) {
      height: unset;
    }
  }

  &__li {
    display: flex;
    align-items: center;
    gap: 7px;
    color: white;
    border: 1px solid transparent;
    cursor: pointer;
    &:hover {
      border: 1px solid $theme-color;
      border-radius: 7px;
      box-shadow: inset 0 0 5px $theme-color;
    }
    &--active {
      color: $theme-color;
    }
  }

  &__col {
    display: flex;
    flex-direction: column;
  }

  &__row {
    display: flex;
    flex-direction: row;
  }

  &__gif {
    display: none;
    margin: 0 7px 0 auto; // хитрий margin
    width: 40px;
    &.active {
      display: block;
    }
  }

  &__img {
    height: 75px;
    width: 75px;
    border-radius: 5px;
    object-fit: cover;
    object-position: center;
    clip-path: inset(10px);
  }

  &__author {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  &__song {
    font-size: 14px;
  }
}
