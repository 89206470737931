@use "../../style/variables.scss" as *;
@use "../../style/mixin.scss" as mixin;

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  &__message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
    margin: 10px;
    font-size: 30px;
    font-weight: bold;
    color: $theme-color;
    &--big {
      text-transform: uppercase;
      font-size: 35px;
      @include mixin.articleTitle;
    }
  }

  &__img {
    width: 350px;
    height: auto;
  }

  &__btn {
    @include mixin.button;
  }
}
